@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AxeHandel";
  src: url("fonts/AxeHandel.ttf");
}

@font-face {
  font-family: "KGLifeisMessy";
  src: url("fonts/KGLifeisMessy.ttf");
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.aspect-h-9 {
  --tw-aspect-h: 9;
}

.aspect-w-16 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 16;
  position: relative;
}

.aspect-w-16 > * {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-2 {
  left: -.5rem;
}

.-top-5 {
  top: -1.25rem;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[1px\] {
  margin-top: 1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-\[420px\] {
  height: 420px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-10 {
  width: 2.5rem;
}

.w-48 {
  width: 12rem;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-72 {
  max-width: 18rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-\[420px\] {
  max-width: 420px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.basis-full {
  flex-basis: 100%;
}

@keyframes infinite-scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.animate-infinite-scroll {
  animation: 15s linear infinite infinite-scroll;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.list-image-\[url\(\'images\/marcador\.png\'\)\] {
  list-style-image: url("marcador.e5046eea.png");
}

.flex-row {
  flex-direction: row;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-0 {
  border-width: 0;
}

.bg-\[\#00ba29\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 186 41 / var(--tw-bg-opacity));
}

.bg-custom1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 249 0 / var(--tw-bg-opacity));
}

.bg-custom2 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 74 246 / var(--tw-bg-opacity));
}

.bg-custom3 {
  --tw-bg-opacity: 1;
  background-color: rgb(43 52 80 / var(--tw-bg-opacity));
}

.bg-custom4 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 0 255 / var(--tw-bg-opacity));
}

.bg-custom5 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 220 254 / var(--tw-bg-opacity));
}

.bg-custom6 {
  --tw-bg-opacity: 1;
  background-color: rgb(50 154 251 / var(--tw-bg-opacity));
}

.bg-custom7 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 170 0 / var(--tw-bg-opacity));
}

.bg-custom8 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 136 255 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-\[url\(\'images\/bg1\.jpg\'\)\] {
  background-image: url("bg1.8809088e.jpg");
}

.bg-\[url\(\'images\/bg2\.jpg\'\)\] {
  background-image: url("bg2.3f72b323.jpg");
}

.bg-\[url\(\'images\/bg3\.jpg\'\)\] {
  background-image: url("bg3.ddc89846.jpg");
}

.bg-\[url\(\'images\/clipping-bg\.jpg\'\)\] {
  background-image: url("clipping-bg.838dc385.jpg");
}

.bg-\[url\(\'images\/excesso-de-informacoes\.jpg\?as\=webp\'\)\] {
  background-image: url("excesso-de-informacoes.70cdaae6.webp");
}

.bg-\[url\(\'images\/fernanda-calligaris\.jpg\?as\=webp\'\)\] {
  background-image: url("fernanda-calligaris.64cb72b7.webp");
}

.bg-\[url\(\'images\/textura1\.jpg\'\)\] {
  background-image: url("textura1.d9c2d7d2.jpg");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.fill-custom2 {
  fill: #1f4af6;
}

.fill-red-500 {
  fill: #ef4444;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.font-custom1 {
  font-family: AxeHandel;
}

.font-custom2 {
  font-family: KGLifeisMessy;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[42px\] {
  font-size: 42px;
}

.text-\[44px\] {
  font-size: 44px;
}

.text-\[50px\] {
  font-size: 50px;
}

.text-\[56px\] {
  font-size: 56px;
}

.text-\[62px\] {
  font-size: 62px;
}

.text-\[64px\] {
  font-size: 64px;
}

.text-\[72px\] {
  font-size: 72px;
}

.text-\[7vw\] {
  font-size: 7vw;
}

.text-\[90px\] {
  font-size: 90px;
}

.text-\[92px\] {
  font-size: 92px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.leading-\[0\.9\] {
  line-height: .9;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-custom2 {
  --tw-text-opacity: 1;
  color: rgb(31 74 246 / var(--tw-text-opacity));
}

.text-custom3 {
  --tw-text-opacity: 1;
  color: rgb(43 52 80 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.placeholder-slate-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@font-face {
  font-family: AxeHandel;
  src: url("AxeHandel.3f200dee.ttf");
}

@font-face {
  font-family: KGLifeisMessy;
  src: url("KGLifeisMessy.9f6af895.ttf");
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-custom2:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 74 246 / var(--tw-ring-opacity));
}

@media (width >= 640px) {
  .sm\:text-5xl\/relaxed {
    font-size: 3rem;
    line-height: 1.625;
  }

  .sm\:text-\[72px\] {
    font-size: 72px;
  }
}

@media (width >= 768px) {
  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-16 {
    gap: 4rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }
}

@media (width >= 1024px) {
  .lg\:text-\[92px\] {
    font-size: 92px;
  }
}

@media (width >= 1280px) {
  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
/*# sourceMappingURL=index.023e329f.css.map */
